import React from 'react'
import FormPage from './subFormPage/SubFormPages'

export default function FormPages() {
   return (
      <section>
         <FormPage />
      </section>
   )
}
