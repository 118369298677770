import { useState } from 'react'
import Backgrounds from '../../assets/images/backgrounds/backgrounds_2.png'
import FAQButtons from '../../components/buttons/subButtons/FAQButtons'
import data from '../../data/faqData'

export default function SeventhPages() {
   const [activeIndex, setActiveIndex] = useState(null);

   const handleFAQClick = (index) => {
      setActiveIndex(activeIndex === index ? null : index);
   };

   return (
      <section
         className="min-h-screen"
         style={{
            backgroundImage: `url(${Backgrounds})`,
            backgroundRepeat: 'repeat',
         }}
         id='faq-screen'
      >
         <section className='pt-32 pb-[16.375rem] xl:w-2/3 mx-4 xl:mx-auto'>
            <section className='flex flex-col text-white mx-auto px-4 py-20 text-center text-5xl font-black font-serif-archivo'>
               <h1 className='mb-[4.5rem]'>Tanya - tanya</h1>
               <section className='flex flex-col gap-4'>
                  {data.map((item, index) => (
                     <FAQButtons
                        key={item.id}
                        question={item.question}
                        answer={item.answer}
                        isOpen={activeIndex === index}
                        onClick={() => handleFAQClick(index)}
                     />
                  ))}
               </section>
            </section>
         </section>
      </section>
   );
}
