import React, { useState } from 'react'

const categories = ['Cincin', 'Anting', 'Gelang', 'Kalung'];

function SubCategoriesButton() {
   const [selectedCategory, setSelectedCategory] = useState('Cincin')
   const [currentIndex, setCurrentIndex] = useState(0)

   const handlePrev = () => {
      if (currentIndex > 0) {
         setCurrentIndex(currentIndex - 1);
         setSelectedCategory(categories[currentIndex - 1]);
      }
   }

   const handleNext = () => {
      if (currentIndex < categories.length - 1) {
         setCurrentIndex(currentIndex + 1);
         setSelectedCategory(categories[currentIndex + 1]);
      }
   }

   return (
      <div className="flex items-center space-x-4">
         <button
            onClick={handlePrev}
            className={`text-2xl ${currentIndex === 0 ? 'text-gray-400' : 'text-gray-800'}`}
            disabled={currentIndex === 0} >
            &lt;
         </button>

         <div className="flex space-x-4">
            {categories.map((category, index) => (
               <button
                  key={category}
                  onClick={() => {
                     setSelectedCategory(category);
                     setCurrentIndex(index);
                  }}
                  className={`font-medium ${selectedCategory === category ? 'text-black underline' : 'text-gray-500'}`}
               >
                  {category}
               </button>
            ))}
         </div>

         {/* Next Button */}
         <button
            onClick={handleNext}
            className={`text-2xl ${currentIndex === categories.length - 1 ? 'text-gray-400' : 'text-gray-800'}`}
            disabled={currentIndex === categories.length - 1}
         >
            &gt;
         </button>
      </div>
   )
}

export default SubCategoriesButton