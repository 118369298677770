import React from 'react'
import Preview from '../../assets/images/decoration/decor_r_1.png'

function DecorSix() {
  return (
      <div>
         <img src={Preview} alt='decor six' />
      </div>
  )
}

export default DecorSix