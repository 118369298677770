import './assets/css/App.css';
import DemoPages from './pages/DemoPages';
import FormPages from './pages/FormPages';
import LandingPages from './pages/LandingPages';
import VTOPages from './pages/VTOPages';
import { Route, Routes } from 'react-router-dom';

function App() {
   return (
      <div className="App">
         <Routes>
            <Route path='/' element={<LandingPages />} />
            <Route path='/demo' element={<DemoPages />} />
            <Route path='/test' element={<VTOPages />} />
            <Route path='/form' element={<FormPages />} />
         </Routes>
      </div>
   );
}

export default App;
