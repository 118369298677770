import React from 'react'
import Backgrounds from '../../assets/images/backgrounds/backgrounds_3.png'
import { Button } from '../../components/buttons'
import { TopDecor, BottomDecor } from '../../components/decoration/BannerDecors'

export default function ContactPages() {
   return (
      <section
         className="py-40 xl:px-[16.25rem] relative z-20"
         style={{
            backgroundImage: `url(${Backgrounds})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover'
         }}
         id='ask-screen'>
         <div className='absolute hidden xl:flex top-0 xl:top-0 -right-[50%] xl:right-0 z-10' > < TopDecor /> </div>
         <div className='absolute bottom-0 left-0 ' > < BottomDecor /> </div>
         <section className='mx-4 z-20 text-center'>
            <h2 className='text-4xl font-bold text-white text-center font-serif-archivo'>Mau ajak ngobrol kita dulu?</h2>
            <p className='text-2xl text-white text-center font-sans-nutito mt-8 mb-[3.625rem]'>Boleh dongs, klik button di bawah ini biar bisa kita kontak yaaa</p>
            <Button.Main onClicks={() => { document.getElementById('intro-screen').scrollIntoView() }}  > Book Demo </Button.Main>
         </section>
      </section>
   )
}
