import React from 'react'
import Img1 from '../../assets/images/character/pays.png'
import Img2 from '../../assets/images/character/uploads.png'
import Img3 from '../../assets/images/character/links.png'
import Img4 from '../../assets/images/character/products.png'
import Decor from '../../components/decoration/Decor'
import DecorTwo from '../../components/decoration/DecorTwo'
import Blurs from '../../assets/images/decoration/Blurs.png'

export default function FourthPages() {
   return (
      <section className='relative' id='feature-screen'>
         <div className='absolute left-0 top-[35%] transform -translate-y-1/2 -z-10 '> <Decor /> </div>
         <div className='absolute right-0 bottom-0 transform -translate-y-1/2 -z-10'> <DecorTwo /> </div>
         {/* <div className='absolute -right-[10%] top-[20%] -z-10 opacity-65 hidden xl:flex'> <Blurs /> </div> */}
         <img src={Blurs} alt='blurs' className='absolute right-0 -top-40 -z-20 hidden xl:flex' />

         <section className='content-center mx-auto relative'>
            {/* ============================= First =============================  */}
            <section className='flex flex-col xl:flex-row xl:mt-8 mx-8 xl:mx-[11.25rem] justify-between'>
               <section className='flex lg:mx-0 mb-8 justify-center w-full xl:w-1/2'>
                  <img src={Img1} alt='one products' className='' />
               </section>

               <div className='mr-8' />

               <section className='flex flex-col w-full xl:w-1/2 xl:flex my-auto text-left text-6xl font-bold font-sans-nutito'>
                  <h2 className='gradient-text'>Bayar sekali, pakai setahun</h2>
                  <p className='text-2xl xl:text-xl font-medium mt-8 text-justify'>
                     Cuma Rp39rb per produk buat setahun penuh. No ribet, no biaya bulanan. Bikin customer happy
                     dan jualan makin laris dengan investasi minim!
                  </p>
               </section>
            </section>

            {/* ============================= Second =============================  */}
            <section className='flex flex-col xl:flex-row xl:mt-8 mx-8 xl:mx-[11.25rem] justify-center'>
               {/* ============================= Desktop Views =============================  */}
               <section className='hidden flex-col w-full xl:w-1/2 xl:flex my-auto text-left text-6xl font-bold font-sans-nutito '>
                  <h2 className='gradient-text'>Cuma Tinggal Upload Foto Ajaaa </h2>
                  <p className='text-2xl xl:text-xl font-medium mt-8 text-justify'>
                     Ga perlu pusing bikin 3D model. Cukup upload foto produk aksesoris kamu, langsung bisa dipakai buat
                     virtual try-on. Easy peasy, super cepat, dan hasilnya keren banget!
                  </p>
               </section>

               <div className='mr-8' />

               <section className='flex lg:mx-0 mb-8 justify-center w-full xl:w-1/2'>
                  <img src={Img2} alt='preview' className='max-w-full' />
               </section>

               {/* ============================= Mobile Views =============================  */}
               <section className='flex flex-col xl:hidden my-auto text-left text-6xl font-bold font-serif-archivo'>
                  <h2 className='gradient-text '>Cuma Tinggal Upload Foto Ajaaa</h2>
                  <p className='text-2xl xl:text-xl mt-8 font-medium'>
                     Ga perlu pusing bikin 3D model. Cukup upload foto produk aksesoris kamu, langsung bisa dipakai buat
                     virtual try-on. Easy peasy, super cepat, dan hasilnya keren banget!
                  </p>
               </section>
            </section>

            {/* ============================= Third =============================  */}
            <section className='flex flex-col xl:flex-row xl:mt-8 mx-8 xl:mx-[11.25rem] justify-between'>
               <section className='flex lg:mx-0 mb-8 justify-center w-full xl:w-1/2'>
                  <img src={Img3} alt='preview' className='max-w-full h-auto' />
               </section>

               <div className='mr-8' />

               <section className='flex flex-col w-full xl:w-1/2 xl:flex my-auto text-left text-6xl font-bold font-sans-nutito '>
                  <h2 className='gradient-text'>Tinggal Share Link Langsung Nyoba~</h2>
                  <p className='text-2xl xl:text-xl font-medium mt-8 text-justify'>
                     Mudah banget! Abis upload dan atur, tinggal share link
                     ke customer kamu. Mereka bisa langsung nyobain
                     produk aksesoris kamu secara virtual. Praktis dan cepet!
                  </p>
               </section>
            </section>

            {/* ============================= Fourth =============================  */}
            <section className='flex flex-col xl:flex-row xl:mt-8 m-20 mx-8 xl:mx-[11.25rem] justify-center'>
               {/* ============================= Desktop Views =============================  */}
               <section className='hidden flex-col w-full xl:w-1/2 xl:flex my-auto text-left text-6xl font-bold font-sans-nutito'>
                  <h2 className='gradient-text'>Bisa Nyambung ke Online Store Kamu Juga</h2>
                  <p className='text-xl font-medium mt-8 text-justify'>
                     Integrasi gampang banget! Tinggal sambungin Couba ke
                     Online Store Kamu Juga, dan customer bisa langsung nyobain
                     produk aksesoris kamu secara virtual dari sana. Praktis dan bikin belanja makin seru!
                  </p>
               </section>

               <div className='mr-8' />

               <section className='flex lg:mx-0 mb-8 justify-center w-full xl:w-1/2'>
                  <img src={Img4} alt='preview' className=' ' />
               </section>

               {/* ============================= Mobile Views =============================  */}
               <section className='flex flex-col xl:hidden my-auto text-left text-6xl font-bold font-serif-archivo '>
                  <h1 className='gradient-text drop-shadow-md'>Bisa Nyambung ke Online Store Kamu Juga</h1>
                  <p className='text-2xl font-medium mt-8'>
                     Integrasi gampang banget! Tinggal sambungin Couba ke online store kamu, dan customer bisa langsung nyobain
                     produk aksesoris kamu secara virtual dari sana. Praktis dan bikin belanja makin seru!
                  </p>
               </section>

            </section>
         </section>


      </section>
   )
}
