import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'framer-motion'
import { Button } from '../buttons/index'
import FormModal from '../ModalForm'
import Hamburger from 'hamburger-react'
// import Logo from '../../assets/images/logo/logo.png'
// import LogoWhite from '../../assets/images/logo/logo_white.png'
import '../../assets/css/index.css'

const info = {
   name: 'Couba',
}

const navBaritems = {
   0: { title: 'How To' },
   1: { title: 'Features' },
   2: { title: 'Products' },
   3: { title: 'Blog' },
   4: { title: 'Book Demo' },
}

export function Navbars({ children }) {
   return (
      <>{children}</>
   )
}

export function MainNavbar({
   NavbarBackgroundColor = 'xl:bg-transparent',
   NavbarMobileBackgroundColor = 'bg-white',
   NavbarHamburgerColor = 'fill-white',
   isSecondary = false,
   logoLight,
   logoDark,
}) {
   const [isOpen, setOpen] = useState(false);
   const [isOpenModal, setIsOpen] = useState(false);

   const toggleMenu = () => setOpen(prev => !prev);
   const toggleModal = () => setIsOpen(prev => !prev);

   useEffect(() => {
      document.body.style.overflow = isOpen ? 'hidden' : 'auto';
   }, [isOpen]);

   return (
      <header className={`flex flex-row relative xl:top-0 custom-sticky z-30 py-3 px-8 xl:py-12 xl:px-[11.25rem] ${NavbarBackgroundColor} ${NavbarMobileBackgroundColor} justify-between text-2xl font-bold content-center shadow-md xl:shadow-none`}>
         <Link to='/' className='mr-10 my-auto z-20'>
            <img src={isSecondary ? logoDark : logoLight} alt={info.name} title={info.name} className='w-[10rem] aspect-auto z-20' />
         </Link>

         <AnimatePresence>
            {isOpen && (
               <motion.nav
                  initial={{ y: -1000 }}
                  animate={{ y: 0, opacity: 1 }}
                  exit={{ y: -1000 }}
                  transition={{ duration: 0.3 }}
                  className='absolute top-0 left-0 w-full h-screen bg-white flex flex-col items-center justify-center'
               >
                  <div className='flex flex-col justify-center mobile-nav items-center text-4xl font-serif-archivo'>
                     <Link onClick={() => { document.getElementById('how-to-screen').scrollIntoView() }} title={navBaritems[0].title} className='mb-4 gradient-text'>{navBaritems[0].title}</Link>
                     <Link onClick={() => { document.getElementById('feature-screen').scrollIntoView() }} title={navBaritems[1].title} className='mb-4 gradient-text'>{navBaritems[1].title}</Link>
                     <Link onClick={() => { document.getElementById('accessories-screen').scrollIntoView() }} title={navBaritems[2].title} className='mb-4 gradient-text'>{navBaritems[2].title}</Link>
                     <Link onClick={() => { document.getElementById('ask-screen').scrollIntoView() }} title={navBaritems[3].title} className='mb-4 gradient-text'>{navBaritems[3].title}</Link>
                     <Link to='/form' title={navBaritems[4].title} className='mt-5 text-mainColor-tertiary'>
                        <Button.Secondary heightButton='py-[1rem]' widthButton='px-[4rem]' >{navBaritems[4].title} </Button.Secondary>
                     </Link>
                  </div>
               </motion.nav>
            )}
         </AnimatePresence>

         <AnimatePresence>
            {isOpenModal && (
               <motion.section
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.2 }}
                  className='fixed flex top-0 left-0 w-screen h-full bg-mainColor-tertiary bg-opacity-80 z-50 items-center justify-center'>
                  <FormModal toggleExit={toggleModal} />
               </motion.section>
            )}
         </AnimatePresence>

         <nav className='lg:flex flex-row items-center hidden font-serif-nutito text-left text-base font-sans-nutito'>
            <Link to='/' title={navBaritems[0].title} className={`mr-[3.2rem] ${isSecondary ? 'text-mainColor-tertiary' : 'text-white'}`}>{navBaritems[0].title}</Link>
            <Link to='/' title={navBaritems[1].title} className={`mr-[3.2rem] ${isSecondary ? 'text-mainColor-tertiary' : 'text-white'}`}>{navBaritems[1].title}</Link>
            <Link to='/' title={navBaritems[2].title} className={`mr-[3.2rem] ${isSecondary ? 'text-mainColor-tertiary' : 'text-white'}`}>{navBaritems[2].title}</Link>
            <Link to='/' title={navBaritems[3].title} className={`mr-[2rem] ${isSecondary ? 'text-mainColor-tertiary' : 'text-white'}`}>{navBaritems[3].title}</Link>
            <Link onClick={toggleModal} title={navBaritems[4].title} className={`ml-[3.2rem] ${isSecondary ? 'text-mainColor-tertiary' : 'text-white'}`}>
               <Button.Secondary heightButton='py-4' widthButton='px-8'>{navBaritems[4].title}</Button.Secondary>
            </Link>
         </nav>

         <nav className='flex lg:hidden items-center ml-[1.875rem] md:mx-0 z-20'>
            <Hamburger toggled={isOpen} toggle={toggleMenu}  />
         </nav>
      </header>
   );
}

/* export function SecondaryNavbar({
   NavbarBackgroundColor = 'xl:bg-mainColor-primary',
   NavbarMobileBackgroundColor = 'bg-white',
   NavbarHamburgerColor = 'fill-white',
}) {
   const [isOpen, setOpen] = useState(false)
   const [isOpenModal, setIsOpen] = useState(false)

   const toggleMenu = () => setOpen(prev => !prev)
   const toggleModal = () => setIsOpen(prev => !prev);

   useEffect(() => {
      if (isOpen) {
         document.body.style.overflow = 'hidden'
      } else {
         document.body.style.overflow = 'auto'
      }
   }, [isOpen])

   return (
      <header className={`
      flex flex-row relative xl:top-0 
      custom-sticky z-30 py-3 
      px-8 xl:py-12 xl:px-[11.25rem] 
      ${NavbarBackgroundColor} 
      ${NavbarMobileBackgroundColor}
      justify-between text-2xl 
      font-bold content-center 
      shadow-md xl:shadow-none`} >
         <Link to='/' className='mr-10 my-auto z-20' >
            <img src={Logo} alt={info.name} title={info.name} className='block xl:hidden w-[10rem] aspect-auto z-20' />
            <img src={LogoWhite} alt={info.name} title={info.name} className='hidden xl:block w-[10rem] aspect-auto z-20' />
         </Link>

         <AnimatePresence>
            {
               isOpen && (
                  <motion.nav
                     initial={{ y: -1000 }}
                     animate={{ y: 0, opacity: 1 }}
                     exit={{ y: -1000 }}
                     transition={{ duration: 0.3 }}
                     className='absolute top-0 left-0 w-full h-screen bg-white flex flex-col items-center justify-center'
                  >
                     <div className='flex flex-col justify-center  mobile-nav items-center text-4xl font-serif-archivo' >
                        <Link onClick={() => { document.getElementById('how-to-screen').scrollIntoView() }} title={navBaritems[0].title} className='mb-4 gradient-text'>{navBaritems[0].title}</Link>
                        <Link onClick={() => { document.getElementById('feature-screen').scrollIntoView() }} title={navBaritems[0].title} className='mb-4 gradient-text'>{navBaritems[1].title}</Link>
                        <Link onClick={() => { document.getElementById('accessories-screen').scrollIntoView() }} title={navBaritems[0].title} className='mb-4 gradient-text'>{navBaritems[2].title}</Link>
                        <Link onClick={() => { document.getElementById('ask-screen').scrollIntoView() }} title={navBaritems[0].title} className='mb-4 gradient-text'>{navBaritems[3].title}</Link>
                        <Link to='/form' title={navBaritems[4].title} className='mt-5 text-mainColor-tertiary'>
                           <Button.Secondary heightButton='py-[1rem]' widthButton='px-[4rem]' >{navBaritems[4].title} </Button.Secondary>
                        </Link>
                     </div>
                  </motion.nav>

               )
            }
         </AnimatePresence>

         <AnimatePresence>
            {
               isOpenModal && (
                  <motion.section
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                     transition={{ duration: 0.2 }}
                     className='fixed flex top-0 left-0 w-screen h-full bg-mainColor-tertiary bg-opacity-80 z-50 items-center justify-center' >
                     <FormModal toggleExit={toggleModal} />
                  </motion.section>
               )
            }
         </AnimatePresence>

         <nav className='lg:flex flex-row items-center hidden font-serif-nutito text-left text-base font-sans-nutito ' >
            <Link to='/' title={navBaritems[0].title} className='mr-[3.2rem] text-white'>{navBaritems[0].title}</Link>
            <Link to='/' title={navBaritems[1].title} className='mr-[3.2rem] text-white'>{navBaritems[1].title}</Link>
            <Link to='/' title={navBaritems[2].title} className='mr-[3.2rem] text-white'>{navBaritems[2].title}</Link>
            <Link to='/' title={navBaritems[3].title} className='mr-[2rem] text-white'>{navBaritems[3].title}</Link>
            <Link onClick={toggleModal} title={navBaritems[4].title} className='ml-[3.2rem] text-mainColor-tertiary'>
               <Button.Secondary heightButton='py-4' widthButton='px-8' >{navBaritems[4].title} </Button.Secondary>
            </Link>
         </nav>

         <nav className='flex lg:hidden items-center ml-[1.875rem] md:mx-0 z-20'>
            <Hamburger toggled={isOpen} toggle={toggleMenu} className='fill-white aspect-auto' />
         </nav>
      </header>
   )
} */

export const Navbar = Object.assign(Navbars, {
   Main: MainNavbar,
   // Secondary: SecondaryNavbar,
})
