import React from 'react'

function PersonIcon() {
   return (
      <svg width="22" height="22" viewBox="0 0 27 27" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M13.6659 13.3592C16.7342 13.3592 19.2215 10.8719 19.2215 7.8036C19.2215 4.73535 16.7342 2.24805 13.6659 2.24805C10.5977 2.24805 8.11035 4.73535 8.11035 7.8036C8.11035 10.8719 10.5977 13.3592 13.6659 13.3592Z" stroke="white" stroke-opacity="0.72" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
         <path d="M23.211 24.4702C23.211 20.1702 18.9332 16.6924 13.6665 16.6924C8.39984 16.6924 4.12207 20.1702 4.12207 24.4702" stroke="white" stroke-opacity="0.72" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
   )
}

export default PersonIcon