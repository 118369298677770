import React from 'react'
import HomePages from './subDemoPage/HomePages'
import { Layout } from '../components/Layouts'

export default function DemoPages() {
  return (
    <Layout.Demo>
      <HomePages />
    </Layout.Demo>
  )
}
