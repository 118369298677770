import React from 'react'
import { Navbar } from './navbar'
import { Footer } from './footer'
import DefaultLogo from '../assets/images/logo/logo.png'
import WhiteLogo from '../assets/images/logo/logo_white.png'

export function Layouts({ children }) {
   <>
      {children}
   </>
}

export function MainLayouts({ children, isSecondary }) {
   return (
      <>
         <Navbar.Main 
            logoDark={DefaultLogo}
            logoLight={WhiteLogo}
            isSecondary={true}
         />
         {children}
         <Footer.Main />
      </>
   )
}

export function DemoLayouts({ children }) {
   return (
      <>
         <Navbar.Main
            logoDark={DefaultLogo}
            logoLight={WhiteLogo}
            isSecondary={false}
            NavbarMobileBackgroundColor='bg-mainColor-primary'
            NavbarBackgroundColor='xl:bg-mainColor-primary'
            NavbarFontColor='text-white'
            NavbarHamburgerColor='text-white'
         />
         {children}
         <Footer.Main />
      </>
   )
}

export const Layout = Object.assign(Layouts, {
   Main: MainLayouts,
   Demo: DemoLayouts
})
