import React from 'react'
import Previews from '../../assets/images/decoration/decor_2.png'

function BlursLeft() {
   return (
      <img src={Previews} alt='preview' className='w-[32rem] xl:w-3/5' />
   )
}

export default BlursLeft
