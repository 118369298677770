import React from 'react'
import { Layout } from '../components/Layouts'
import IntroPages from './subLandingPage/IntroPages'
import ProblemPages from './subLandingPage/ProblemPages'
import HowToPages from './subLandingPage/HowToPages'
import FeaturePages from './subLandingPage/FeaturePages'
import ProductPages from './subLandingPage/AccessoriesPages'
import ComingSoonPages from './subLandingPage/ComingSoonPages'
import FAQPages from './subLandingPage/FAQPages'
import ContactPages from './subLandingPage/ContactPages'

function LandingPages() {
   return (
      <Layout.Main>
         <IntroPages />
         <ProblemPages />
         <HowToPages />
         <FeaturePages />
         <ProductPages />
         <ComingSoonPages />
         <FAQPages />
         <ContactPages />
      </Layout.Main>
   )
}

export default LandingPages