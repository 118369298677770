import React from 'react'
import { Card } from '../../components/cards/'
import data from '../../data/iconData'

export default function ComingSoonPages() {
  return (
    <section className='py-11 xl:py-[5.5rem] px-8 xl:px-[12.063rem]'>
      <section className='flex flex-col justify-center text-center'>
         <h2 className='font-black text-5xl py-[4.35rem] gradient-text font-serif-archivo'>Coming Soon~</h2>

         <section className='flex flex-col md:flex-row xl:flex-row justify-center text-3xl'>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[0].icon} heightCards='mt-2' image={data[0].url} paddingImages='p-8' ></Card.Main>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[1].icon} heightCards='mt-2' image={data[1].url} paddingImages='p-8' ></Card.Main>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[2].icon} heightCards='mt-2' image={data[2].url} paddingImages='p-8' ></Card.Main>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[3].icon} heightCards='mt-2' image={data[3].url} paddingImages='p-8' ></Card.Main>
         </section>

         {/* <div className='mt-4'></div> */}

         <section className='flex flex-col md:flex-row xl:flex-row justify-center xl:mx-auto text-3xl xl:w-3/4'>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[4].icon} heightCards='mt-2' image={data[4].url} paddingImages='p-8' ></Card.Main>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[5].icon} heightCards='mt-2' image={data[5].url} paddingImages='p-8' ></Card.Main>
            <Card.Main marginCard='m-2 xl:mx-2' title={data[6].icon} heightCards='mt-2' image={data[6].url} paddingImages='p-8' ></Card.Main>
         </section>
      </section>
    </section>
  )
}
