import React from 'react'
import Preview from '../assets/images/decoration/DecorFormSide.png'
import { Inputs } from './inputs'
import BannerDecor from '../assets/images/decoration/BannerHand.png'
import BannerDecors from '../assets/images/decoration/BannerHandFront.png'
import SemiLogo from '../assets/images/logo/semi_logo.png'
import Exit from '../assets/svg/exit.svg'
import BannerMobile from '../assets/images/decoration/DecorMobiles.png'
import ImageHandsLeft from '../assets/images/decoration/HandsMobileLeft.png'
import ImageHandsRight from '../assets/images/decoration/HandsMobileRight.png'

import { Button } from './buttons'

function ModalForm({ toggleExit }) {
   return (
      <section className='w-5/6 md:w-3/4'>

         <section className='bg-mainColor-tertiary translate-x-2 translate-y-2 rounded-2xl'>
            <section className='flex flex-col md:flex-row relative h-3/5 bg-white -translate-x-2 -translate-y-2 rounded-2xl justify-evenly'>

               <div className='absolute rounded-full -right-5 -top-5'>
                  <button className='w-12 h-12 
                     bg-mainColor-primary 
                     rounded-full flex justify-center items-center
                     text-white font-bold'
                     onClick={toggleExit} >
                     <img src={Exit} alt='exit' />
                  </button>
               </div>

               <form className='
                  flex flex-col 
                  bg-mainColor-primary
                  px-4 xl:px-32 xl:pl-24 xl:pr-12 pt-4 pb-4
                  justify-center rounded-tl-2xl rounded-tr-2xl
                  md:rounded-tr-none
                  md:rounded-tl-2xl md:rounded-bl-2xl 
                  text-left w-auto md:w-3/5'>
                  <h1 className='text-2xl xl:text-4xl text-white font-bold text-center md:text-left'>Isi data diri kamu dulu yuk</h1>

                  <Inputs.MainForm> Full Name </Inputs.MainForm>
                  <Inputs.MainForm> Phone Number </Inputs.MainForm>
                  <Inputs.MainForm> Email </Inputs.MainForm>
                  <Inputs.MainForm> Brand </Inputs.MainForm>
                  <div className='mb-8' />
                  <Button.VariantsSecondary to='/test' sizeButtonHeight='py-2' sizeButtonWidth='px-12'>Submit</Button.VariantsSecondary>
               </form>

               <section className='flex md:hidden relative'>
                  <img src={ImageHandsLeft} alt='banner' className='absolute left-[40%] -bottom-4 -z-10' />
                  <img src={ImageHandsRight} alt='banner' className='absolute left-[25%] -bottom-[30%]' />
                  <img src={BannerMobile} alt='banner' className='w-full' />
                  <img src={SemiLogo} alt='Couba' className='absolute top-[90%] right-5 w-2/6 rotate-12' />
               </section>

               <section className='hidden md:flex relative w-1/5'>
                  <img src={BannerDecor} alt='banner' className='absolute bottom-[30%] -left-[10%] scale-75' />
                  <img src={Preview} alt='preview' className='h-full rounded-tr-2xl rounded-br-xl z-10' />
                  <img src={BannerDecors} alt='banner' className='absolute bottom-[10%] -left-[5%] z-20' />
               </section>

               <section className='hidden md:flex flex-col relative w-1/5 max-h-20 aspect-square my-auto'>
                  <div className='flex h-60' />
                  <img src={SemiLogo} alt='Couba' className='flex w-5/6' />
               </section>

            </section>

         </section>
      </section>
   )
}

export default ModalForm