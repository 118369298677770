import React, { } from 'react'
import Preview from '../../assets/images/previews.png'
import Couba from '../../assets/images/logo/logo.png'
import Blurs from '../../components/decoration/Blurs'
import { Button } from '../../components/buttons'
import Bag from '../../assets/images/details/Bag.png'
import Thunder from '../../assets/images/details/Light.png'
import Mobile from '../../assets/images/details/Phone.png'
import Clouds from '../../assets/images/details/Clouds.png'
import { motion } from 'framer-motion'

export default function FirstPages() {

   return (
      <section id='intro-screen' className='relative'>

         <section className='absolute -top-[60%] -left-60 -z-10 hidden xl:flex '> <Blurs /> </section>
         <motion.section
            className='flex flex-col xl:flex-row mt-20 xl:mt-8 mx-8 xl:mx-[11.25rem] justify-between'
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1 }}
         >
            <section className='my-auto text-left text-4xl xl:w-2/4'>
               <h1 className="font-black font-serif-archivo text-mainColor-tertiary">
                  Ga ribet! Buat Customer bisa
                  <span className="relative inline-block align-middle">
                     <img src={Couba} alt="preview" className="w-32 inline-block relative top-1" />
                  </span>
                  produk aksesorismu
               </h1>
               <p className='text-2xl my-8 font-sans-nutito'>Virtual try on paling simpellll. Share link aja kak, customer lsg bisa nyoba!.</p>
               <Button.Main to='/demo'>Coba Sekarang</Button.Main>
               
            </section>

            <section className='mx-16 mb-8 xl:mb-0'></section>

            <section className='relative xl:w-2/4 mx-auto lg:mx-0 mb-32'>
               <div className='relative'>
                  <img src={Bag} alt=' preview' className='absolute w-1/5 -top-[90%] -left-[10%]' />
               </div>
               <div className='relative'>
                  <img src={Thunder} alt=' preview' className='absolute w-1/6 -top-16 -right-[0%]' />
               </div>
               <img src={Preview} alt='preview' className='xl:w-auto mx-auto' />
               <div className='relative'>
                  <img src={Mobile} alt=' preview' className='absolute w-1/5 -bottom-10 -left-0 -rotate-12' />
               </div>
               <div className='relative'>
                  <img src={Clouds} alt=' preview' className='absolute w-1/3 -top-52 right-12  -rotate-12' />
               </div>
            </section>
         </motion.section>
      </section>
   )
}
