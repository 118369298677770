import React from 'react'
import Previews from '../../assets/images/character/problems.png'
import BlursRight from '../../components/decoration/BlursTwo'
import BlursLeft from '../../components/decoration/BlursThree'
import DecorThree from '../../components/decoration/DecorThree'

export default function SecondPages() {
   return (
      <section className='relative' id='problem-screen'>
         <div className='absolute inset-y-0 left-0 flex items-center -z-10'>
            <BlursLeft />
         </div>
         <section className='flex flex-col xl:flex-row m-20 xl:mt-8 mx-8 xl:mx-[11.25rem] justify-between'>
            <section 
               className='hidden flex-col xl:flex my-auto 
               text-left text-4xl font-black font-serif-archivo 
               leading-snug gradient-text w-2/4
               '>
               <h1>Susah jualan online karena customer ga bisa nyobain dulu?</h1>

               <p className='text-xl my-8 font-medium leading-normal text-black text-left'>
                  Ga perlu khawatir lagi! Dengan virtual try on Couba, customer bisa nyoba produk aksesorismu secara virtual.
                  Jadi bakal ningkatin kepercayaan dan keinginan membeli!
               </p>
            </section>

            <section className='mx-16 mb-8 xl:mb-0'></section>

            <section className='flex xl:w-2/4 lg:mx-0 mb-8 justify-center'>
               <img src={Previews} alt='preview' className='w-[32rem] xl:w-auto' />
            </section>

            <section className='flex flex-col xl:hidden my-auto text-left text-4xl font-bold font-serif-archivo gradient-text'>
               <h1>Susah jualan online Karena customer ga bisa nyobain dulu?</h1>

               <p className='text-xl my-8 font-semibold text-mainColor-tertiary text-justify'>
                  Ga perlu khawatir lagi! Dengan virtual try on Couba, customer bisa nyoba produk aksesorismu secara virtual.
                  Jadi bakal ningkatin kepercayaan dan keinginan membeli!
               </p>
            </section>
         </section>

         <div className='absolute inset-y-0 -top-[75%]  right-0 -z-10'>
            <BlursRight />
         </div>
         <div className='absolute inset-y-0 top-[70%]  right-0 -z-10'>
            <DecorThree />
         </div>


      </section>
   )
}
