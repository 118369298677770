import React from 'react'
import { Link } from 'react-router-dom'

export function Buttons({ children }) {
   return (
      <>{children}</>
   )
}

export function MainButtons({
   children,
   to,
   sizeWidth = 'px-[3.13rem]',
   sizeHeight = 'py-[1.2rem]',
   buttonColors = 'bg-mainColor-primary',
   textColor = 'text-mainColor-cream',
   sizeWidthButton = '',
   onClicks
}) {
   return (
      <Link to={to}>
         <button
            onClick={onClicks}
            className={`
                  button-main ${buttonColors} ${textColor} text-base font-semibold font-sans-nutito 
                  ${sizeHeight} ${sizeWidth} ${sizeWidthButton} 
                  rounded-full border-2 border-b-8 border-mainColor-tertiary`}>
            {children}
         </button>
      </Link>
   )
}

export function SecondaryButton({
   children,
   heightButton = 'py-[0.8rem]',
   widthButton = 'px-[1rem]'
}) {
   return (
      <div className='bg-mainColor-tertiary translate-y-1 rounded-lg'>
         <button className={`
            -translate-y-1
            button-secondary 
            text-lg font-bold 
            rounded-lg border-2 border-mainColor-tertiary
            ${heightButton} ${widthButton}`}>
            {children}
         </button>
      </div>
   )
}

export function VariantsButton({
   children,
   to,
   buttonColors,
   textColor,
   sizeHeight 
}) {
   return (
      <Link to={to}>
         <button
            className={`
               button-variants ${buttonColors} ${textColor} text-base font-semibold font-sans-nutito 
               ${sizeHeight} px-[1.5rem] 
               rounded-full border-2 border-b-8 border-mainColor-tertiary`}>
            {children}
         </button>
      </Link>
   )
}

export function VariantsButtonWhite({ 
   children,
   sizeButtonHeight = 'py-[1.2rem]',
   sizeButtonWidth = 'px-[3.13rem]',
   buttonFullWidth = 'w-min',
   to
}) {
   return (
      <Link className={`bg-mainColor-tertiary translate-y-1 rounded-full ${buttonFullWidth}`} to={to}>
         <button className={`
            -translate-y-1 ${buttonFullWidth}
            bg-mainColor-primary button-variant-secondary
            text-mainColor-cream text-lg font-semibold font-sans-nutito 
            ${sizeButtonHeight} ${sizeButtonWidth} rounded-full 
            border-2 border-mainColor-tertiary`}>
            {children}
         </button>
      </Link>
   )
}

export const Button = Object.assign(Buttons, {
   Main: MainButtons,
   Secondary: SecondaryButton,
   Variants: VariantsButton,
   VariantsSecondary: VariantsButtonWhite
})

