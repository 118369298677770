import React from 'react'
import Backgrounds from '../../assets/images/backgrounds/backgrounds.png'
import ButtonSwitch from '../../components/buttons/subButtons/ButtonSwitch'
import Arrows from '../../assets/images/arrows.png'
import CardsOne from '../../assets/images/card_1.png'
import CardsTwo from '../../assets/images/card_2.png'
import CardsThird from '../../assets/images/card_3.png'

export default function ThirdPages() {
   return (
      <section
         className=" min-h-screen bg-cover bg-center pb-32"
         style={{
            backgroundImage: `url(${Backgrounds})`,
            backgroundRepeat: 'no-repeat',
         }}
         id='how-to-screen'
      >
         <section className='flex flex-col text-white mt-36'>
            <section className="flex flex-col xl:flex-col mx-auto px-4 py-20 text-center text-5xl font-black font-serif-archivo">
               <h2 className='mb-4 mx-auto'>Pake Couba aja! Ga ribet kok, cuma 3 step:</h2>
               {/* <h2 className='mb-4 mx-auto'>Ga ribet kok, cuma 3 step:</h2> */}
            </section>

            <section className="hidden mx-auto px-4 pb-20 sm:flex">
               <main className=''> <ButtonSwitch /> </main>
            </section>

            <section className="flex flex-col xl:flex-row mx-11 mb-60 justify-center">

               <main className='flex justify-center'>
                  <img src={CardsOne} alt='arrows' className='w-[24rem]' />
               </main>

               <main className='flex justify-center'>
                  <img src={Arrows} alt='arrows' className='w-16 h-16 xl:my-auto my-4 mx-4 rotate-90 xl:rotate-0' />
               </main>

               <main className='flex justify-center'>
                  <img src={CardsTwo} alt='arrows' className='w-[24rem]' />
               </main>

               <main className='flex justify-center'>
                  <img src={Arrows} alt='arrows' className='w-16 h-16 xl:my-auto my-4 mx-4 rotate-90 xl:rotate-0' />
               </main>

               <main className='flex justify-center'>
                  <img src={CardsThird} alt='arrows' className='w-[24rem]' />
               </main>

            </section>
         </section>

      </section>
   )
}
