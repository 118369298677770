import React, { useEffect, useState } from 'react'
import data from '../../data/accesoriesData'
import { Card } from '../../components/cards'
import { Button } from '../../components/buttons/'
import FormModal from '../../components/ModalForm'
import Decors from '../../components/decoration/Decor6'
import { AnimatePresence, motion } from 'framer-motion'
import BannerDecor from '../../components/decoration/BannerDecor'
import SearchButton from '../../components/buttons/subButtons/ButtonSearch'
import CategoriesButton from '../../components/buttons/subButtons/CategoriesButton'
import SubCategoriesButton from '../../components/buttons/subButtons/SubCategoriesButton'


export default function HomePage() {
   const [isOpenModal, setIsOpen] = useState(false)

   const toggleModal = () => setIsOpen(prev => !prev);

   useEffect(() => {
      window.scrollTo(0, 0);
   }, []);

   return (
      <section>
         <div className='absolute right-0 top-[120%] transform translate-y-1/2 -z-10 '> <Decors /> </div>
         <div className='absolute left-0 top-0 transform -z-10 '> <BannerDecor /> </div>

         <AnimatePresence>
            {
               isOpenModal && (
                  <motion.section
                     initial={{ opacity: 0 }}
                     animate={{ opacity: 1 }}
                     exit={{ opacity: 0 }}
                     transition={{ duration: 0.2 }}
                     className='fixed flex top-0 left-0 w-screen h-full bg-mainColor-tertiary bg-opacity-80 z-50 items-center justify-center' >
                     <FormModal toggleExit={toggleModal} />
                  </motion.section>
               )
            }
         </AnimatePresence>

         <section className='mx-8 xl:mx-[11.25rem] mb-20 xl:mb-8'>
            <section className='flex flex-col md:flex-col lg:flex-col xl:flex-row justify-between my-8'>
               <CategoriesButton />
               <div className='mx-0 md:mx-0 lg:mx-0 xl:mx-4' />
               <SearchButton onClicks={console.log('clicked')} />
            </section>
            <section className='flex flex-col md:flex-col lg:flex-col xl:flex-row justify-between my-8'>
               <SubCategoriesButton />
            </section>

            <section className='bg-mainColor-tertiary rounded-2xl translate-x-2 translate-y-2 text-center'>
               <main className='grid gap-4 sm:grid-cols-2 lg:grid-cols-4 2xl:grid-cols-4 
                  border-2 border-mainColor-tertiary -translate-x-2 -translate-y-2
                  justify-between bg-mainColor-primary py-4 xl:py-16 px-4 xl:px-12 rounded-2xl'>
                     {
                        data.map((item) => {
                           return <Card.Main key={item.id} image={item.img} heightCards='my-2' >
                              <Button.Main
                                 onClicks={toggleModal}
                                 sizeHeight='py-4'
                                 sizeWidth='px-2'
                                 sizeWidthButton='w-4/5'
                              >
                                 Coba Gratis
                              </Button.Main>
                           </Card.Main>
                        })
                     }
               </main>
            </section>
         </section>
      </section>
   )
}
