import React from 'react'
import Previews from '../../assets/images/decoration/TopBanner.png'
import Previewss from '../../assets/images/decoration/BottomLeftBanner.png'

export function TopDecor() {
  return (
      <div>
         <img src={Previews} alt='TopBanner' />
      </div>
  )
}

export function BottomDecor() {
   return (
      <div>
         <img src={Previewss} alt='BottomBanner' />
      </div>
   )
}