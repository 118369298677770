import img1 from '../assets/images/previews/1.png'
import img2 from '../assets/images/previews/2.gif'
import img3 from '../assets/images/previews/3.gif'
import img4 from '../assets/images/previews/4.gif'

const demoData = [
   {
      id: 1,
      name: 'Anting',
      to: '/',
      image: img1,
   },
   {
      id: 2,
      name: 'Cincin',
      to: '/',
      image: img2,
   },
   {
      id: 3,
      name: 'Kalung',
      to: '/',
      image: img3,
   },
   {
      id: 4,
      name: 'Gelang',
      to: '/',
      image: img4,
   },
]

export default demoData