import React from 'react';
// import React, { useEffect, useRef, useState } from 'react';
// import Hands from '../assets/images/VTO/hands.png';
// import Logo from '../assets/images/VTO/Logo.png';
// import Powered from '../assets/images/VTO/PoweredBy.png';
// import { motion } from 'framer-motion';

export default function VTOPages() {
   /* const videoRef = useRef(null);
   const [isVisible, setIsVisible] = useState(true);

   useEffect(() => {
      async function getCameraStream() {
         try {
            const stream = await navigator.mediaDevices.getUserMedia({ video: true });
            if (videoRef.current) {
               videoRef.current.srcObject = stream;
            }
         } catch (error) {
            console.error('Error accessing camera: ', error);
         }
      }

      getCameraStream();

      return () => {
         if (videoRef.current && videoRef.current.srcObject) {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());
         }
      };
   }, []);


   useEffect(() => {
      const timer = setTimeout(() => {
         setIsVisible(false);
      }, 7000);

      return () => clearTimeout(timer);
   }, []); */

   return (
      <div className="fixed inset-0 flex items-center justify-center bg-black">

         {/* <section className="absolute top-0 left-0 flex flex-col xl:w-full xl:h-full">
            <motion.div
               initial={{ opacity: 0 }}
               animate={{ opacity: isVisible ? 2 : 0 }}
               transition={{ duration: 2 }}
               className="flex justify-center"
            >
               <motion.div
                  initial={{ opacity: 0 }} animate={{ opacity: 2 }}
                  transition={{ duration: 2 }} className="flex relative flex-col top-28 text-2xl text-white"
               >
                  Show your right / left back of the hand closer to the camera
               </motion.div>
               <img src={Hands} alt="hands" className="absolute xl:w-1/6 xl:top-[30%]" />
            </motion.div>
         </section>

         <section className="absolute -bottom-[40%] left-0 flex flex-row items-center justify-center h-screen w-full xl:h-full">
            <img src={Powered} alt="powered by" className="mx-2" />
            <img src={Logo} alt="logo" className="mx-2" />
         </section>

         <video
            ref={videoRef}
            className="rounded-2xl h-screen w-auto mx-auto object-cover"
            autoPlay
            playsInline
         /> */}

      </div>
   );
}
