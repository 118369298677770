const questions = [
   {
      id: 1,
      question: 'How to setup subscription renewals?',
      answer: 'To ensure you always have a valid account to access, by default, your subscription automatically renews either quarterly or annually.'
   },
   {
      id: 2,
      question: "Can I customize virtual try-on's UI or UX?",
      answer: "Depends on your subscription plan, you have different functionalities to customize through Online Service console. For example, in the Module Configuration: The launcher templates and the model photos are both customizable in the Layout Settings section. Furthermore, you can also find more customizable options, like enabling the sharing feature, in the Advanced Settings section."
   },
   {
      id: 3,
      question: 'Do you store user photos on your server?',
      answer: "Photos taken within the virtual try-on experience are saved locally in the client device's storage only and not stored anywhere else. We do not save users photos on our server."
   },
   {
      id: 4,
      question: 'What happens if I subscribe to both Makeup & Eyewear Online Services?',
      answer: "You will be billed seperately for both subscription plans. For example, if you subscribe to the annual premium plan for Makeup Online Service and the monthly essential plan for Eyewear Online Service, you will be charged for both plans the moment you subscribe."
   },
   {
      id: 5,
      question: 'How to request a refund?',
      answer: 'If you are not satisfied with your subscription, you can request a refund within 30 days of your initial purchase. Please contact customer support for assistance.'
   }
]

export default questions;
