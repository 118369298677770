import React, { useState } from 'react'
import { motion } from 'framer-motion'
import ArrowIcon from '../../icons/ArrowIcon'

const categories = ['Beauty', 'Jewelry', 'Eyewear', 'Watches', 'Outfit']

function CategoriesButton() {
   const [selectedCategory, setSelectedCategory] = useState('Jewelry')

   return (
      <motion.div
         className="flex items-center border border-gray-300 rounded-xl md:overflow-hidden flex-wrap px-6 my-4 space-x-8 justify-between"
         initial={{ opacity: 0 }}
         animate={{ opacity: 1 }}
         transition={{ duration: 0.5 }}
      >
         <motion.button
            className="text-gray-600 mr-2"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
         >
            <ArrowIcon rotateIcon='transform rotate-180' />
         </motion.button>

         {categories.map((category) => (
            <motion.button
               key={category}
               onClick={() => setSelectedCategory(category)}
               className={`xl:px-4 py-4 rounded-xl focus:outline-none font-sans-nutito ${selectedCategory === category
                  ? 'bg-mainColor-primary text-white'
                  : 'text-gray-800'
               }`}
               whileHover={{ scale: 1.1 }}
               whileTap={{ scale: 0.95 }}
            >
               {category}
            </motion.button>
         ))}

         <motion.button
            className="text-gray-600 ml-2"
            whileHover={{ scale: 1.2 }}
            whileTap={{ scale: 0.9 }}
         >
            <ArrowIcon rotateIcon='' />
         </motion.button>
      </motion.div>
   )
}

export default CategoriesButton