import React from 'react'
import Preview from '../../assets/images/decoration/decor_l_1.png'

function BannerDecor() {
   return (
      <div>
         <img src={Preview} alt='decor six' />
      </div>
   )
}

export default BannerDecor