import img1 from '../assets/images/accessories/1.jpeg'
import img2 from '../assets/images/accessories/2.jpeg'
import img3 from '../assets/images/accessories/3.jpeg'

const accessoriesData = [
    {
        id: 1,
        img: img1
    },
    {
        id: 2,
        img: img2
    },
    {
        id: 3,
        img: img3
    },
    {
        id: 4,
        img: img1
    },
    {
        id: 5,
        img: img2
    },
    {
        id: 6,
        img: img3
    },
    {
        id: 7,
        img: img1
    },
    {
        id: 8,
        img: img2
    },
    {
        id: 9,
        img: img3
    },
    {
        id: 10,
        img: img1
    },
    {
        id: 11,
        img: img2
    },
    {
        id: 12,
        img: img3
    }
]

export default accessoriesData