import React from 'react'
// import CardsAcces from '../../components/Cards'
import { Card } from '../../components/cards/'
import { Link } from 'react-router-dom'
import { Button } from '../../components/buttons'
import data from '../../data/demoData'
import DecorFour from '../../components/decoration/DecorFour'
import DecorFive from '../../components/decoration/DecorFive'

// const sizeWidth = 'px-24'

export default function FifthPages() {
   return (
      <section className='relative' id='accessories-screen'>
         
         <section className='flex flex-col bg-mainColor-primary p-8 pb-36 z-20'>
            <nav className='flex flex-row justify-between xl:justify-around items-center mt-[5.315rem] mb-14'>
               <h1 className='text-left text-4xl font-black font-serif-archivo text-white'>
                  Tipe Aksesoris
               </h1>
               <Link to='/demo' className='text-right text-xl font-normal font-sans-nutito text-white hover:underline'>
                  Lihat Semua
               </Link>
            </nav>

            <section className='grid grid-cols-1 gap-8 lg:grid-cols-2 w-full xl:w-2/4 xl:mx-auto z-20'>
               <section className='flex flex-col text-4xl font-bold font-serif-archivo text-mainColor-tertiary text-center'>
                  <Card.Main topPadding='pt-8' horizotalPadding='px-8' image={data[0].image} heightCards='my-4' title={data[0].name}>
                     <Button.Main to='/demo' sizeWidth='px-16 xl:px-12' sizeHeight='py-4' sizeWidthButton='mx-auto'>Coba Gratis</Button.Main>
                  </Card.Main>
               </section>

               <section className='flex flex-col text-4xl font-bold font-serif-archivo text-mainColor-tertiary text-center'>
                  <Card.Main topPadding='pt-8' horizotalPadding='px-8' image={data[1].image} heightCards='my-4' title={data[1].name}>
                     <Button.Main to='/demo' sizeWidth='px-16 xl:px-12' sizeHeight='py-4' sizeWidthButton='mx-auto'>Coba Gratis</Button.Main>
                  </Card.Main>
               </section>

               <section className='flex flex-col text-4xl font-bold font-serif-archivo text-mainColor-tertiary text-center'>
                  <Card.Main topPadding='pt-8' horizotalPadding='px-8' image={data[2].image} heightCards='my-4' title={data[2].name}>
                     <Button.Main to='/demo' sizeWidth='px-16 xl:px-12' sizeHeight='py-4' sizeWidthButton='mx-auto'>Coba Gratis</Button.Main>
                  </Card.Main>
               </section>

               <section className='flex flex-col text-4xl font-bold font-serif-archivo text-mainColor-tertiary text-center'>
                  <Card.Main topPadding='pt-8' horizotalPadding='px-8' image={data[3].image} heightCards='my-4' title={data[3].name}>
                     <Button.Main to='/demo' sizeWidth='px-16 xl:px-12' sizeHeight='py-4' sizeWidthButton='mx-auto'>Coba Gratis</Button.Main>
                  </Card.Main>
               </section>
            </section>
         </section>
         <div className='absolute right-0 bottom-[55%] xl:bottom-[30%] transform -translate-y-1/2 z-10'>
            <DecorFour />
         </div>
         <div className='absolute left-0 bottom-[2%] xl:-bottom-[12%] transform -translate-y-1/2 z-10'>
            <DecorFive />
         </div>

      </section>
   )
}
