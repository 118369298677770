import React from 'react'
import { Button } from '../../components/buttons'
import { Inputs } from '../../components/inputs'
import SemiLogo from '../../assets/images/logo/semi_logo.png'
import Preview from '../../assets/images/decoration/DecorFormSide.png'
import BannerDecor from '../../assets/images/decoration/BannerHand.png'
import BannerDecors from '../../assets/images/decoration/BannerHandFront.png'
import BannerMobile from '../../assets/images/decoration/DecorMobiles.png'
import ImageHandsLeft from '../../assets/images/decoration/HandsMobileLeft.png'
import ImageHandsRight from '../../assets/images/decoration/HandsMobileRight.png'

export default function SubFormPages() {
   return (
      <section className='md:m-0'>
         <section className='flex flex-col md:flex-row md:h-screen'>

            <form className='
               flex flex-col 
               bg-mainColor-primary
               px-32 pl-12 md:pl-24 pr-12 pt-4 pb-4
               justify-center
               text-left md:w-3/5 h-full'>
               <h1 className='text-2xl text-white font-bold text-center md:text-left'>Isi data diri kamu dulu yuk</h1>

               <Inputs.MainForm>Full Name</Inputs.MainForm>
               <Inputs.MainForm>Phone Number</Inputs.MainForm>
               <Inputs.MainForm>Email</Inputs.MainForm>
               <Inputs.MainForm>Brand</Inputs.MainForm>
               <div className='mb-8' />
               <Button.VariantsSecondary to='/test' sizeButtonHeight='py-4' sizeButtonWidth='px-12' buttonFullWidth='w-full lg:w-min'>Submit</Button.VariantsSecondary>
            </form>

            <section className='flex md:hidden relative'>
               <img src={ImageHandsLeft} alt='banner' className='absolute left-[40%] -bottom-4 -z-10' />
               <img src={ImageHandsRight} alt='banner' className='absolute left-[25%] -bottom-[30%]' />
               <img src={BannerMobile} alt='banner' className='w-full' />
               <img src={SemiLogo} alt='Couba' className='absolute top-[80%] right-5 w-2/6 rotate-12' />
            </section>

            <section className='hidden md:flex relative w-1/5'>
               <img src={BannerDecor} alt='banner' className='absolute bottom-[30%] -left-[10%] scale-75' />
               <img src={Preview} alt='preview' className='h-full rounded-tr-2xl rounded-br-xl z-10' />
               <img src={BannerDecors} alt='banner' className='absolute bottom-[10%] -left-[5%] z-20' />
            </section>

            <section className='hidden md:flex flex-col relative w-1/5 max-h-20 aspect-square my-auto'>
               <div className='flex h-60' />
               <img src={SemiLogo} alt='Couba' className='flex w-5/6' />
            </section>

         </section>
      </section>
   )
}
