import React from 'react'

export function Cards({ children }) {
   return (
      <>{children}</>
   )
}

export function MainCard({
   title,
   children,
   image = 'https://via.placeholder.com/400',
   heightCards = 'my-[2.188rem]',
   paddingImages = 'p-0',
   horizotalPadding = 'px-2',
   topPadding = 'pt-2',
   marginCard = 'mx-0'
}) {
   return (
      <>
         <section className={`bg-mainColor-tertiary translate-x-1 translate-y-1 rounded-3xl ${marginCard}`}>
            <section className={`-translate-x-1 -translate-y-1
               flex flex-col justify-center 
               bg-white pb-4 ${horizotalPadding} ${topPadding} 
               rounded-3xl border-2 border-mainColor-tertiary `}>
               <section className='flex flex-col xl:flex my-auto text-justify font-bold font-serif-archivo'>
                  <img
                     src={image}
                     alt={title}
                     title={title}
                     className={`aspect-square object-cover ${paddingImages} w-full rounded-3xl border-2 border-mainColor-tertiary bg-mainColor-primary`}
                  />
               </section>
               <section className={`
               flex flex-col xl:flex 
               text-center font-serif-archivo 
               text-2xl ${heightCards} `}>
                  {title}
               </section>
               <section>
                  {children}
               </section>
            </section>
         </section>
      </>
   )
}

export const Card = Object.assign(Cards, {
   Main: MainCard
})