import { Link } from "react-router-dom";
import { Button } from "../buttons";
import { Input } from "../inputs";
import Logo from "../../assets/images/logo/logo_white.png";

export function FooterContainer({ children }) {
   return <>{children}</>;
}

export function FooterNavbar({ children }) {
   return (
      <section className="flex-col mx-11 mt-12 text-center text-white text-3xl font-black">
         {children}
      </section>
   );
}

export function FooterNavigation({ children }) {
   return (
      <section className="flex flex-col justify-center font-sans-nutito">
         {children}
      </section>
   );
}

export function FooterInformation({ children }) {
   return (
      <section className="flex flex-col lg:flex-row justify-between m-12 mx-8 xl:mx-[7.38rem] text-white">
         {children}
      </section>
   );
}

export function FooterInformationLeft({ children }) {
   return (
      <section className="flex flex-col text-sm xl:text-base font-medium">
         {children}
      </section>
   );
}

export function FooterInformationRight({ children }) {
   return (
      <section className="grid gap-4 grid-cols-2 md:grid-cols-3 xl:grid-cols-3 text-left text-sm mt-8 xl:mt-0">
         {children}
      </section>
   );
}

export function FooterInformationNavigation({ children }) {
   return (
      <section className="flex flex-col mr-[1.315rem]">
         {children}
      </section>
   );
}

export function FooterInformationNavigationSecond({ children }) {
   return (
      <section className="flex flex-col">
         {children}
      </section>
   );
}

export function FooterInformationNavigationThird({ children }) {
   return (
      <section className="flex flex-col mt-8 md:mt-0">
         {children}
      </section>
   );
}

export function FooterNewsLetter({ children }) {
   return (
      <section className="flex flex-col xl:flex-row mx-8 xl:mx-[7.38rem] justify-between">
         <section className='flex pr-5 text-left text-white text-xl xl:text-2xl my-auto'> Join newsletter kita untuk terima update terbaru </section>
         {children}
      </section>
   );
}

export function FooterNewsLetterInput() {
   return (
      <section className='flex flex-col sm:flex-row justify-between mt-10 xl:mt-0 text-center'>
         <Input.Main> Masukkan email kamu </Input.Main>
         <div className='w-[1.5rem] my-2 xl:flex' />
         <Button.Main sizeHeight='py-4' sizeWidth='px-12'> Subscribe </Button.Main>
      </section>
   );
}

export function FooterLegalInformation({ children }) {
   return (
      <section className=' text-white'>
         <div className='border-t-[1.3px] border-white opacity-30 mx-8 xl:mx-[4.25rem] mt-11' />
         {children}
      </section>
   );
}

export function FooterLegalInformationContent({ children }) {
   return (
      <section className='flex flex-col xl:flex-row  xl:justify-between mx-[7.38rem] mt-12 mb-[5.25rem] '>
         {children}
         <section className='flex xl:hidden justify-center'>  <p className='text-white text-lg mr-0 xl:mr-4 my-4 font-normal'>© 2023 Couba</p> </section>
      </section>
   );
}

export function FooterLegalInformationBrand() {
   return (
      <section className='hidden xl:flex justify-center'>
         <p className='text-white text-sm mr-4 font-normal'>© 2023 Couba</p>
      </section>
   );
}

export function FooterLegalInformationPrivacyPolicy() {
   return (
      <section className='flex flex-col xl:flex-row justify-center text-center'>
         <Link href='/' className='font-normal xl:my-0 my-2 text-sm mr-0 xl:mr-[5.315rem]' title='Privacy Policy'>Privacy Policy</Link>
         <Link href='/' className='font-normal xl:my-0 my-2 text-sm mr-0 xl:mr-[5.315rem]' title='Terms of Service'>Terms of Service</Link>
         <Link href='/' className='font-normal xl:my-0 my-2 text-sm' title='Cookies'>Cookies</Link>
      </section>
   );
}

export function MainFooter() {
   return (
      <footer className='flex flex-col bg-mainColor-tertiary justify-center'>
         <Footer.Navbar>
            <h1 className='mt-12 mb-6'>Bingung atau butuh bantuan?</h1>
            <Button.Main> Hubungi Kami </Button.Main>
         </Footer.Navbar>

         <Footer.Navigation>
            <div className='border-t-[1.3px] border-white opacity-30 mx-8 xl:mx-[4.25rem] mt-11' />
            <Footer.Information>
               <Footer.InformationLeft>
                  <img src={Logo} alt='Logo' className='w-[10rem] mb-4' />
                  <div className='flex flex-col text-left '>
                     <p>The Lapan Square,</p>
                     <p>Jl. Jurang Mangu Bar. No 88,</p>
                     <p>Jurang Mangu Barat</p>
                  </div>
               </Footer.InformationLeft>
               <Footer.InformationRight>
                  <Footer.InformationNavigation>
                     <Link to='/' onClick={() => { document.getElementById('intro-screen').scrollIntoView() }} className='font-medium mb-[1.315rem]'>Home</Link>
                     <Link to='/' onClick={() => { document.getElementById('feature-screen').scrollIntoView() }} className='font-medium mb-[1.315rem]'>Feature</Link>
                     <Link to='/' onClick={() => { document.getElementById('accessories-screen').scrollIntoView() }} className='font-medium mb-[1.315rem]'>Product</Link>
                     <Link to='/' onClick={() => { document.getElementById('how-to-screen').scrollIntoView() }} className='font-medium'>How it Works</Link>
                  </Footer.InformationNavigation>
                  <Footer.InformationNavigationSecond>
                     <Link href='/' className='font-medium mb-[1.315rem]'>Pricing</Link>
                     <Link href='/' className='font-medium mb-[1.315rem]'>FAQ</Link>
                     <Link href='/' className='font-medium mb-[1.315rem]'>Integration</Link>
                     <Link href='/' className='font-medium'>Use Cases</Link>
                  </Footer.InformationNavigationSecond>
                  <Footer.InformationNavigationThird>
                     <Link href='/' className='font-medium mb-[1.315rem]'>Help Center</Link>
                     <Link href='/' className='font-medium mb-[1.315rem]'>Community</Link>
                     <Link href='/' className='font-medium mb-[1.315rem]'>Video Course</Link>
                     <Link href='/' className='font-medium'>Demo</Link>
                  </Footer.InformationNavigationThird>

               </Footer.InformationRight>
            </Footer.Information>
         </Footer.Navigation>

         <Footer.NewsLetter>
            <Footer.NewsLetterInput />
         </Footer.NewsLetter>

         <Footer.LegalInformation>
            <Footer.LegalInformationContent>
               <Footer.LegalInformationBrand />
               <Footer.LegalInformationPrivacyPolicy />
            </Footer.LegalInformationContent>
         </Footer.LegalInformation>
      </footer>
   )
}

export const Footer = Object.assign(FooterContainer, {
   Navbar: FooterNavbar,
   Navigation: FooterNavigation,
   Information: FooterInformation,
   InformationLeft: FooterInformationLeft,
   InformationRight: FooterInformationRight,
   InformationNavigation: FooterInformationNavigation,
   InformationNavigationSecond: FooterInformationNavigationSecond,
   InformationNavigationThird: FooterInformationNavigationThird,
   NewsLetter: FooterNewsLetter,
   NewsLetterInput: FooterNewsLetterInput,
   LegalInformation: FooterLegalInformation,
   LegalInformationBrand: FooterLegalInformationBrand,
   LegalInformationContent: FooterLegalInformationContent,
   LegalInformationPrivacyPolicy: FooterLegalInformationPrivacyPolicy,
   Main: MainFooter
});