import React from 'react'

function Blurs({blurRadius = 'blur-2xl'}) {
   return (
      <div className={`
         w-[42rem] h-[42rem] 
         bg-mainColor-secondary 
         rounded-full ${blurRadius} opacity-50
      `}>
         
      </div>
   )
}

export default Blurs